import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import ScrollToTop from "./helpers/ScrollToTop";
import AgencyLandingPage from "demos/AgencyLandingPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<AgencyLandingPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;