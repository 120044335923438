import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
function WhatsApp() {

  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+529986952937"
        accountName="Hastech Apps"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Hello there! Welcome to Hastech Apps.  How can we be of service to you today"
        // avatar={img}
        statusMessage="Typically replies in less than 1hr"

      />

    </div>
  );
}

export default WhatsApp;