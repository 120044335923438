import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Mobile Development",
      description: "We build robust, high-performance mobile apps tailored to your business goals, making sure your brand stands out on both iOS and Android platforms."
    },
    {
      imageSrc: SupportIconImage,
      title: "Web Development",
      description: "Our websites aren't just visually appealing—they're also fast, secure, and designed to deliver a seamless user experience across all devices."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "UI/UX Design",
      description: "We design intuitive and engaging interfaces that provide a delightful user experience, turning visitors into loyal customers."
    },
    {
      imageSrc: FastIconImage,
      title: "Software Consulting",
      description: "Get expert advice on the best software solutions for your business. We’ll help you make strategic decisions to drive growth and efficiency."
    },
    {
      imageSrc: ReliableIconImage,
      title: "Maintenance & Support",
      description: "From regular updates to troubleshooting, we’ve got your back. Our team ensures your software is always running smoothly and efficiently."
    },
    {
      imageSrc: SimpleIconImage,
      title: "Custom Software Solutions",
      description: "No two businesses are alike. We develop custom software solutions tailored to your unique needs, helping you achieve your business objectives."
    },
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Our <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Service"}</span>
                <p className="description">
                  {card.description || "Description of the service offered."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
